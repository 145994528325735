import React, { Component } from "react";
import "./Footer.css";

class Footer extends React.Component {
  state = {
    bounce: ""
  };
  listenScrollEvent = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.scrollHeight - 20
    ) {
      this.setState({ bounce: "bounce" });
    } else {
      this.setState({ bounce: "" });
    }
  };
  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent);
  }

  scrollTo = e => {
    let main = document.querySelector(".main");
    main.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };
  render() {
    return (
      <div className="footer-component">
        <button
          onClick={this.scrollTo}
          className={`fas fa-angle-double-up fa-2x ${this.state.bounce}`}
          href="#"
        />
        <div className="to-top">To Top</div>
      </div>
    );
  }
}

export default Footer;
