import React, { Component } from "react";
import "./MainContent.css";
import SocialIcons from "./SocialIcons";
import Header from "./Header/Header.js";
import Footer from "./Footer/Footer";

export class MainContent extends React.Component {
  state = {
    display: "show"
  };

  scrollTo = e => {
    let about = document.getElementById("about");
    about.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };
  listenScrollEvent = () => {
    if (window.scrollY > 250) {
      this.setState({ display: "hidden" });
    } else {
      this.setState({ display: "show" });
    }
  };
  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    return (
      <div className="main">
        <Header />
        <div className="hero">
          <div>Zack Horwitz</div>
          <div className="email">Zack@horwitz.dev</div>
          <div className="skills">
            <i className="fab fa-js" />
            <i className="fab fa-react" />
            <i className="fab fa-node-js" />
            <i className="fab fa-html5" />
          </div>
          <SocialIcons />
        </div>
        <div className="footer-component-down">
          <button
            onClick={this.scrollTo}
            className={`down-arrow fas fa-angle-double-down fa-2x ${
              this.state.display
            }`}
            href="#"
          />
        </div>
        <Footer />
      </div>
    );
  }
}
