import React, { Component } from "react";
import { MainContent } from "./MainContent";
import About from "./About/About";
import "./Home.css";

import Portfolio from "./Portfolio/Portfolio";

class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <MainContent />
        <About />
        <Portfolio />
      </div>
    );
  }
}

export default Home;
