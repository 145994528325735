import React, { Component } from "react";
import "./About.css";
import Header from "./../Header/Header";

class About extends React.Component {
  state = {
    display: "show"
  };
  scrollTo = e => {
    let portfolio = document.getElementById("portfolio");
    portfolio.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };
  listenScrollEvent = () => {
    if (window.scrollY > 900 || window.scrollY < 700) {
      this.setState({ display: "hidden" });
    } else {
      this.setState({ display: "show" });
    }
  };
  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent);
  }
  render() {
    return (
      <div id="about">
        <div className="about-subdiv">
          <div className="about-container">
            <p className="about-me">About Me</p>
          </div>
          <div className="about-content">
            <p>Developer based out of Detroit,MI</p>
            <p>Skillset includes:</p>
            <ul className="skills-list">
              <li>Javascript</li>
              <li>React</li>
              <li>Redux</li>
              <li>MongoDB</li>
              <li>Express</li>
              <li>Node.js</li>
            </ul>
          </div>
          <div className="footer-component-about">
            <button
              onClick={this.scrollTo}
              className={`down-arrow fas fa-angle-double-down fa-2x ${
                this.state.display
              }`}
              href="#"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default About;
