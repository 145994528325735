import React from "react";
import ReactDOM from "react-dom";
import Home from "./components/Home";
import Library from "./components/Library";
import Life from "./components/Game";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <div>
      <Route exact path="/" component={Home} />
      <Route path="/weather" component={App} />
      <Route path="/library" component={Library} />
      <Route path="/life" component={Life} />
    </div>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
